import useWorkspace from '@components/hooks/useWorkspace';
import ListPage from '@components/pages/list';
import { useGetLocale, useGo, useParsed, useTranslate } from '@refinedev/core';
import { ColumnDef } from '@tanstack/react-table';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parseISO from 'date-fns/parseISO';
import { GetStaticPaths, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useMemo } from 'react';

interface Project {
  id: string;
  title: string;
}

function ProjectList() {
  const [workspaceId] = useWorkspace();
  const t = useTranslate();

  const gotTo = useGo();
  const columnDefs: ColumnDef<Project>[] = useMemo(() => {
    return [
      {
        id: 'title',
        header: t('app:list.headers.title'),
        accessorKey: 'title',
      },
      {
        id: 'id',
        header: t('app:list.headers.id'),
        accessorKey: 'id',
      },
      {
        id: 'updatedAt',
        header: t('app:list.headers.updated_at'),
        accessorKey: '$updatedAt',
        cell: (p) => {
          return formatDistanceToNow(parseISO(p.getValue() as string), {
            addSuffix: true,
          });
        },
      },
    ];
  }, [t]);
  return (
    <ListPage
      onRowClick={(projectId: string) => {
        gotTo({
          to: `/${workspaceId}/${projectId}`,
          type: 'push',
        });
      }}
      title="app:list.project_title"
      description="app:list.project_description"
      columns={columnDefs}
      refineCoreProps={{
        resource: process.env.NEXT_PUBLIC_RID_PROJECT,
        queryOptions: {
          enabled: !!workspaceId,
        },
        filters: {
          permanent: [
            {
              field: 'workspaceId',
              operator: 'eq',
              value: workspaceId,
            },
          ],
        },
      }}
    />
  );
}

export default ProjectList;

ProjectList.layout = 'app';

export const getStaticProps: GetStaticProps = async (context) => {
  const i18nProps = await serverSideTranslations(context.locale ?? 'en', [
    'common',
    'app',
  ]);

  return {
    props: {
      ...i18nProps,
    },
  };
};

export const getStaticPaths: GetStaticPaths = (context) => {
  return {
    paths: [],
    fallback: true,
  };
};
