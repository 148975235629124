import { useGo, useParsed } from '@refinedev/core';

// this will provide the PanelContext;
export default function useWorkspace() {
  const { params } = useParsed();
  const workspaceId = params?.workspaceId;
  const goTo = useGo();
  const setWorkspace = (value) => {
    goTo({
      to: `/${value}`,
      type: 'push',
    });
  };

  return [workspaceId, setWorkspace];
}
