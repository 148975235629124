import { HttpError, useTableProps, useTranslate } from '@refinedev/core';
import { UseTableProps, useTable } from '@refinedev/react-table';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import { Button } from '@components/Button';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Loading from '@components/Loading';

interface ListPageProps<T> {
  title: string;
  description: string;
  columns: ColumnDef<T>[];
  refineCoreProps: UseTableProps<T, HttpError>['refineCoreProps'];
  onCreateNew?: () => void;
  onRowClick?: (id: string) => void;
}
export default function ListPage<T>({
  columns,
  title,
  description,
  onRowClick,
  onCreateNew,
  refineCoreProps,
}: ListPageProps<T>) {
  const {
    getHeaderGroups,
    getRowModel,
    getState,
    setPageIndex,
    getCanPreviousPage,
    getPageCount,
    getCanNextPage,
    nextPage,
    previousPage,
    setPageSize,
    getPrePaginationRowModel,
    refineCore: {
      tableQueryResult: { isLoading },
    },
  } = useTable({
    columns,
    refineCoreProps,
  });

  const t = useTranslate();

  return (
    <div className="z-0 w-full px-4 py-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white/90">
            {t(title)}
          </h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-white/60">
            {t(description)}
          </p>
        </div>
        {onCreateNew && (
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Button type="button" onClick={onCreateNew}>
              {t('app:list.create_new')}
            </Button>
          </div>
        )}
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table
              className={clsx({ 'table-zebra': !onRowClick }, 'table w-full')}
            >
              <thead>
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id}>
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className="flex justify-between gap-2"
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <ChevronUpIcon className="h-3 w-3" />,
                                  desc: <ChevronDownIcon className="h-3 w-3" />,
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {getRowModel().rows.length > 0 ? (
                  getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={clsx({ 'hover cursor-pointer': onRowClick })}
                        onClick={() => onRowClick(row.renderValue('id'))}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr>
                    <td colSpan={columns.length} align="center">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={columns.length} align="center">
                      {t('app:list.empty')}
                    </td>
                  </tr>
                )}
              </tbody>
              {getRowModel().rows.length > 0 && (
                <tfoot>
                  {getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th key={header.id}>
                            {header.isPlaceholder ? null : (
                              <>
                                <div
                                  className="flex items-center justify-between gap-2"
                                  onClick={header.column.getToggleSortingHandler()}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: <ChevronUpIcon className="h-3 w-3" />,
                                    desc: (
                                      <ChevronDownIcon className="h-3 w-3" />
                                    ),
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              </>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </tfoot>
              )}
            </table>
            {/* This is just a very basic UI implementation: */}
            <div className="flex items-center gap-2 pt-2">
              <div className="!btn-group !btn-group-horizontal">
                <Button
                  onClick={() => setPageIndex(0)}
                  disabled={!getCanPreviousPage()}
                >
                  <ChevronDoubleLeftIcon className="h-4 w-4" />
                </Button>
                <Button
                  onClick={() => previousPage()}
                  disabled={!getCanPreviousPage()}
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                </Button>
              </div>
              <div>
                <strong>{getState().pagination.pageIndex + 1}</strong> of{' '}
                {getPageCount()}
              </div>
              <div className="!btn-group !btn-group-horizontal">
                <Button onClick={() => nextPage()} disabled={!getCanNextPage()}>
                  <ChevronRightIcon className="h-4 w-4" />
                </Button>
                <Button
                  onClick={() => setPageIndex(getPageCount() - 1)}
                  disabled={!getCanNextPage()}
                >
                  <ChevronDoubleRightIcon className="h-4 w-4" />
                </Button>
              </div>

              {/* <div>
                  | Go to page:
                  <input
                    type="number"
                    defaultValue={getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      setPageIndex(page);
                    }}
                  />
                </div> */}
              <div className="px-6">
                {getPrePaginationRowModel().rows.length} items
              </div>

              <select
                className="select-bordered select select-sm text-xs"
                value={getState().pagination.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
